import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async downloadExcel(filter) {
        const response = await httpService.downloadExcelWithFilter('Reports/ReportPembayaranVsPenagihanExcel', filter);
        return response;
    },
    async getList(filter) 
    {
        const response = await httpService.get(`Reports/ReportPembayaranVsPenagihan`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },
    
    default() {
        return {
            id: null,
            vendorName:null,
            soldToName:null,
            location:null,
            qtyMiroInKL:null,
            qtySOInKLTertagih:null,
            qtySOInKLTidakTertagih:null
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.vendorName = jsonData.vendorName;
        result.soldToName = jsonData.soldToName;
        result.locationCode = jsonData.locationCode;
        result.qtyMIROInKL = jsonData.qtyMIROInKL;
        result.qtySOInKLPaid = jsonData.qtySOInKLPaid;
        result.qtySOInKLNotPaid = jsonData.qtySOInKLNotPaid;

        return result;
    }
});