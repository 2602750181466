<template>
    <router-view></router-view>

    <bs-search-form>
        <template v-slot:title>Report Pembayaran vs Penagihan</template>
        <template v-slot:content>
            <div class="col-lg-6 col-sm-12">

                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Tahun</label>
                    <div class="col-sm-8">
                        <year-combo v-model="filter.yearEquals" />
                    </div>
                </div>

                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Bulan</label>
                    <div class="col-sm-8">
                        <month-combo v-model="filter.monthEquals" />
                    </div>
                </div>

            </div>

            <button class="btn btn-primary btn-sm" @click="loadData()"><i class="bi bi-search"></i> Search</button>

            <button class="btn btn-primary btn-sm ms-1" @click="downloadExcel">
                <i class="bi bi-file-earmark-excel"></i> Download Excel
            </button>
        </template>
    </bs-search-form>


    <div class="row justify-content-between mt-3">
        <div class="col-md-6">
            
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-stripped">
            <thead>
                <tr>
                    <th>Vendor Name</th>
                    <th>Sold to Name</th>
                    <th>Location</th>
                    <th>Qty MIRO in KL</th>
                    <th>Qty SO in KL Tertagih</th>
                    <th>Qty SO in KL Tidak Tertagih</th>  
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id">
                        <td>{{item.vendorName}}</td>
                        <td>{{item.soldToName}}</td>
                        <td>{{item.locationCode}}</td>
                        <td>
                            <bs-plain-number v-model="item.qtyMIROInKL" />
                        </td>
                        <td>
                            <bs-plain-number v-model="item.qtySOInKLPaid" />
                        </td>
                        <td>
                            <bs-plain-number v-model="item.qtySOInKLNotPaid" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import ReportPembayaranVsPenagihanService from "@/services/report-pembayaran-vs-penagihan-service";

export default {
    data: function () {
        return {
            filter: {
                yearEquals: new Date().getFullYear(),
                monthEquals: new Date().getMonth() + 1
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array(),
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/report-pembayaran-vs-penagihan") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async loadData() {
            try {
                const result = await ReportPembayaranVsPenagihanService.getList(this.filter);
                
                const data = result.data;

                this.pageNumber = data.pageNumber;
                this.perPage = data.perPage;
                this.totalPage = data.totalPage;
                this.totalRow = data.totalRow;
                this.data = data.data;
            } 
            catch (e) {
                this.pageNumber = 0;
                this.perPage = 0;
                this.totalPage = 0;
                this.totalRow = 0;
                this.data = Array();
            }
        },
        async downloadExcel() {
          try {
            this.$store.commit('progressCounterQueueIncrement');
            await ReportPembayaranVsPenagihanService.downloadExcel(this.filter);
          }
          finally {
            this.$store.commit('progressCounterQueueDecrement');
          }
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>